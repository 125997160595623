<template>
  <div>
    <navbar-top left="back"
                middle="title"
                @cl="back">
      <p class="text-2xl text-white font-semibold">Колода</p>
    </navbar-top>

    <div class="fixed inset-0 pt-14 bg-blue-850 overflow-y-auto"
         v-touch:swipe.right="() => back()">
      <div v-if="dataLoaded"
           class="wrapper">
        <div class="flex flex-col p-6 sm:px-10">
          <h1 class="text-3xl leading-tight text-white">{{ deck.title }}</h1>
          <p class="text-white">{{ formatFcCount(deck.fcs.length) }}</p>
          <hr class="my-3">
          <router-link v-for="card in deck.fcs"
                       :to="{ name: 'PageFlash', params: { cardId: card._id } }"
                       :key="card._id"
                       class="my-2 p-3 rounded-xl bg-blue-600">
            <div class="flash-texts flex">
              <font-awesome-icon v-if="hasImages(card)"
                                 icon="images"
                                 class="text-white mt-1 ml-1 mr-2 inline-block"></font-awesome-icon>
              <p class="text-white inline-block">{{ thumbText(card) }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>


    <keypress key-event="keyup" :multiple-keys="keyboard.back" @success="back" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Keypress from 'vue-keypress';
import NavbarTop from '@/components/NavbarTop';
import MenuSpinner from '@/components/MenuSpinner.vue';
import { formatFcCount } from '@/assets/functions';

export default {
  components: { NavbarTop, MenuSpinner, Keypress },
  props: { deckId: String },
  data () {
    return {
      dataLoaded: false,
      keyboard: {
        back: [
          { keyCode: 27, modifiers: [], preventDefault: false }, // ESC
        ],
      },
    };
  },
  computed: {
    ...mapState({
      deck: state => state.deck.deck,
    }),
  },
  created () {
    this.$store.dispatch('deck/fetchDeck', this.deckId)
      .then(() => this.dataLoaded = true);
  },
  methods: {
    formatFcCount: formatFcCount,
    hasImages (card) {
      return [...card.question, ...card.answer]
          .some(block => block.blockType === 'image');
    },
    thumbText (card) {
      const firstBlock = card.question[0];
      if (firstBlock.blockType === 'text') {
        const stripped = firstBlock.content
            .replace(/__|\*\*|==|%%/g, '')
            .replace(/[.?!;:]\n/g, '\0 ')
            .replace(/\n/g, '. ');
        const cut = stripped.slice(0,50);

        return cut.length < stripped.length ? cut + '...' : cut;
      }
      else if (firstBlock.blockType === 'image') return 'Картинка...';
    },
    back () { this.$router.push('/'); },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: block;
  margin: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 639px) {
    width: 100%;
  }

  @media (min-width: 640px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
}
</style>
